<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div class="inbu-faq">
    <h2>个体户注册常见问题</h2>
    <div class="container">
      <!-- <h2>常见问题</h2> -->
      <h5>1、为什么要注册个体工商户？</h5>
      <p>
        答：自由职业者的收入所得是介于生产经营所得和劳务所得中间的定义，现在国家没有明确判定自由职业者这部分收入是生产经营所得还是劳务所得，注册个体工商户，收入来源均为生产经营所得，国家对于生产经营所得收入有明确税收政策。注册个体户是为了让大家在平台上收入合规，同时也是为了给大家的收入按经营所得完税，<span style="color:red">不合并</span>在综合所得的年度汇算清缴。
      </p>

      <h5>2、为什么个体工商户的注册地在温州？</h5>
      <p>
        答：因为温州弗达供应链有限公司和浙江僖友数字科技有限公司为总部经济引进优质企业，我们是作为总部经济招商平台，由温州弗达供应链有限公司和浙江僖友数字科技有限公司招商引进的个体户及公司，政府给到的政策为单人季度30万以内免增值税+个税。
        <br />
        注意：关于公职人员兼职行为，目前规定，经组织批准可以兼职，但不得取酬，所以不可以注册个体工商户。
        <br />
        法律上规定的是具有完全民事行为能力的自然人都是可以注册的（未成年人员除外）
      </p>

      <h5>3、 注册个体工商户是否需要去当地银行开户？</h5>
      <p>
        答：不需要，只需要个人银行储蓄卡（非信用卡）。
      </p>

      <h5>4、个体户是否可以开对公账户？</h5>
      <p>
        答：如果客户强制要开对公账户，个体户可以开对公账户，但是需要法人本人去银行开户，另外开对公账户不能享受核定征收，需要查账征收，如您这边需要开对公账户，营业执照和公章我们寄给您之后，您这边需要自己去当地办理，需要您这边建账查账征收，不能享受现在的季度30万双税减免的政策。
      </p>

      <h5>5、 个体工商户注册包含哪些服务？</h5>
      <p>
        答：{{isXyk}}代办注册个体工商户服务包括：办公场地租赁、工商注册、刻章印章、公安局备案、税务登记、税种核定、购买发票、记账报税、年度发票管理等服务。
      </p>

      <h5>6、个税如何计算，每月限额多少？</h5>
      <p>
        答：个税计算由税务局系统根据开票金额及开票类目统一核定结算；
        龙港、苍南针对个体户客户月收入10万元以内（季度30万元）免增值税和个税，月收入超出10万元（季度30万元）个税按照全额收入的0.4%征收，税费客户自行承担。（注：现有税收优惠政策截止2021年12月31号，新政策待2022年1月同步更新）
      </p>

      <h5>7、个体工商户注册后，对每月业绩是否有考核指标？</h5>
      <p>
        答：个体工商户注册完成后，对每月经营业绩没有指标考核。
      </p>

      <h5>8、如果注销是否有经营期限要求？</h5>
      <p>
        答：个体工商户注册完成后，如客户想要注销，对实际经营期限没有要求，但需客户通知我们办税人员，注销个体户需先对注册个体户清税，清税完成后方可注销操作。
      </p>

      <h5>9、一年有效期后第二年费用如何收取？</h5>
      <p>
        答：根据首次合同签订注册服务费标准同步收取。
      </p>

      <h5>10、是否可以自己开具发票？具体是什么类型发票？</h5>
      <p>
        答：不能自己开票，由我们弗达/僖友代开，开具发票为电子普票
      </p>

      <h5>11、哪几种情况需要注销个体户？</h5>
      <p>
        答：终止经营活动需注销个体户：
        <br />
        （1）个体户长期没有经营活动的情况
        <br />
        （2）合同期满未续费
        <br />
        （3）B端客户通知要求注销
        <br />
        （4）个体户要求注销
      </p>

      <h5>12、个体户是否可以开具专票？</h5>
      <p>
        答：可以开具专票，开专票需要客户建立对公账户，需要法人到银行开户。
      </p>

      <h5>13、注册个体户客户反馈收到很多电话反馈是否要代理记账、税务外包等等电话，是否存在信息泄露？</h5>
      <p>
        答：我司会在系统和保密规范上严格管理内部工作人员，防范和避免信息泄露。只是在提交工商信息后，注册信息泄露目前确实存在，现在是全国联网，至于哪个环节出去的也很难核查。我们已多次投诉市场监督管理局，他们也很无奈。我们自己也经常接到电话。这个应该是跟市场监督局打通系统的第三方，企查查天眼查这类的企业，不管在哪成立一家公司，立刻就有服务机构电话上门，如有收到电话无需处理即可。
      </p>

      <h5>14、自己注册个体户可以吗，为什么要花钱请你们办理？</h5>
      <p>
        答：自己可以办理，只是我们办理您将省去注册、开票、报税、注销、场地租赁等一系列繁琐的办理流程，给您提供便捷服务。
      </p>

      <h5>15、{{isXyk}}的服务内容没有银行开户的服务，个体户可以不开公户吗？</h5>
      <p>
        答：可以不开立公账。根据《个体工商户条例》第二十条规定，个体户可以不开立公户，允许以个人账户进行资金往来结算；另外，出于对个体户业主个人的成本考虑，注册个体户公账年费得好几百块，而且很多银行提供身份证原件或要求本人到场办理。
      </p>

      <h5>16、{{isXyk}}的《个体户服务协议》中的服务内容没有代理记账服务，个体户可以不记账吗？</h5>
      <p>
        答：需要记账，但可以简易记账。通过「{{isXyk}}」注册的个体户，采用的用核定征收方式缴纳个人所得税，核定征收是只根据申报收入缴税，不用管成本费用，所以提供申报数据统计表简易记账即可。
      </p>

      <h5>17、只进行工商注册，不用税务登记，就不用纳税，税局也不知道，是吗？</h5>
      <p>
        答：根据税收征收管理法第十五条，工商行政管理机关应当将办理登记注册、核发营业执照的情况，定期向税务机关通报。现在是五证合一，定期通报基本是每天都在进行，工商注册后资料会自动流转到税务部门，税局是清清楚楚的。
      </p>

      <h5>18、只进行工商注册拿营业执照，不进行税务登记，有什么后果？</h5>
      <p>
        答：工商注册后，不进行税务登记，将面临罚款，严重的吊销营业执照，影响经营。根据税收征收管理法第十五条、六十条规定，个体工商户应在领取营业执照之日起三十日内，向税务机关申报办理税务登记。未按照规定的期限申报办理税务登记，可以处二千元以下的罚款;情节严重的，处二千元以上一万元以下的罚款；拒不办理的，经税务机关提请，由工商行政管理机关吊销其营业执照。
      </p>

      <h5>19、消费者要发票，可以拒绝开票吗？</h5>
      <p>
        答：不可以。根据《发票管理办法》第十九条，二十条、三十五条规定，开具发票是销售方的法定义务，取得发票是购买方（消费者）法定权利，不得剥夺。拒绝开具发票，由税务机关责令改正，可以处1万元以下的罚款；有违法所得的予以没收。
      </p>

      <h5>20、不开发票的收入，需要纳税吗？税局能查得到不开票的收入吗？</h5>
      <p>
        答：除免税收入和不征税收入不用纳税外，凡是个体户的经营收入均需要申报纳税，对于不开发票的收入，在申报表上填写在不开票收入即可。现在国家税务总局金税三期系统，它可以将微信、支付宝、银行卡的收入做大数据的匹配，所以不再是不会查到，而是什么时候进行秋后算账的问题。
      </p>

      <h5>21、不按时纳税申报有什么后果？</h5>
      <p>
        答：将面临补缴税款，滞纳金和罚款的风险。根据《税收征收管理法》第六十二条规定，纳税人未按照规定的期限办理纳税申报，可以处二千元以下的罚款;情节严重的，可以处二千元以上一万元以下的罚款。第六十四条规定，纳税人不进行纳税申报，不缴或者少缴应纳税款的，由税务机关追缴其不缴或者少缴的税款、滞纳金，并处不缴或者少缴的税款百分之五十以上五倍以下的罚款。
      </p>

      <h5>22、个体工商没有向税务局报送报表有什么后果？</h5>
      <p>
        答：根据《税收征收管理法》第二十五条、六十二条规定，纳税人必须报送纳税申报表、财务会计报表等纳税资料；不报送的由税务机关责令限期改正，可以处二千元以下的罚款;情节严重的，可以处二千元以上一万元以下的罚款。
      </p>

      <h5>23、个体工商户没有做工商年报有什么后果？</h5>
      <p>
        答：（1）企业未按时进行工商年报的，将被工商部门列入经营异常名录；若满3年企业还未移出的，将被列入严重违法失信企业名单；<br />
        （2）被列入严重违法失信名单后，企业将不能贷款、投资、出入境、参与招投标、政府采购等，严重影响个人发展；<br />
        （3）被列入严重违法失信名单后，企业法人等在进行注册新公司、经营、从业任职资格等都将被限制；<br />
        （4）被列入严重违法失信名单后，在企业与他人合作时信用将会遭到质疑，严重影响公司的长远发展。
      </p>

      <h5>24、注册个体工商户只拿营业执照，不进行税务登记、报税等，用两三个月后直接注销个体户，可以吗？</h5>
      <p>
        答：不进行税务登记等，会影响注销。注销个体户工商部门要求提供税务机关提供的清税文书，不办理税务登记的，税务机关提供不了。所以在领取营业执照后30日内必须做税务登记。
      </p>

      <h5>25、已经拥有一家公司并且已经是法人了，能不能再注册个体工商户？</h5>
      <p>
        答：可以注册，但是不可以和这家公司有任何的资金往来。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "faq",
  data(){
    return {
      isXyk:window.location.origin.indexOf('h5.kylincloud-saas') > -1 ?'麒麟云服' :'僖游客'
    }
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.inbu-faq {
  h2 {
    color: black;
    text-align: center;
    margin-top: 3vw;
  }
  .container {
    padding: 15px 10px;
    margin-bottom: 50px;
  }
  .container h5{
    margin:8px 0;
  }
  .container p {
    background-color: #f9f8f8;
    color: #333;
    line-height: 30px;
    display: block;
    padding:5px;
    font-size:0.9rem;
    white-space: pre-wrap;
  }
}

</style>